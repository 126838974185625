import axios from "axios";
import { APP_URLs, config } from "../constants/appURLs";
import { IThirdPartyAPI } from "../constants/types";
import { snackActions } from "../helpers/SnackUtilsConfigurator";

// Golf Query
const getAllGolfClub = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.golfEndPoints.getAllGolf}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&type=${query?.type ?? ""}&golfType=${
        query?.golfType ?? ""
      }&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getGolfClub = async (golfId: string) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.golfEndPoints.golfCommonAPI}/${golfId}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addGolfClub = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.golfEndPoints.golfCommonAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const deleteGolfClub = async (payloadData: any) => {
  try {
    const { data } = await axios.delete(
      `${APP_URLs.golfEndPoints.golfCommonAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const activeDeActiveGolfClub = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.golfEndPoints.activeDeactiveGolf,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addEditGolfHoles = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.golfEndPoints.addEditGolfHoles,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

// Common
const getCountries = async () => {
  try {
    const { data } = await axios.get(
      APP_URLs.commonEndPoints.getCountries,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getStates = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.commonEndPoints.getStates,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getCities = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.commonEndPoints.getCities,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getTerms = async () => {
  try {
    const { data } = await axios.get(
      APP_URLs.termsAndCondition.getTerms,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getPrivacy = async () => {
  try {
    const { data } = await axios.get(
      APP_URLs.privacyAndPolicy.getPrivacy,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getPaymentTerms = async () => {
  try {
    const { data } = await axios.get(
      APP_URLs.paymentTerms.getPaymentTerms,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getPaymentPrivacy = async () => {
  try {
    const { data } = await axios.get(
      APP_URLs.paymentPrivacy.getPaymentPrivacy,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};
const getAboutUs = async () => {
  try {
    const { data } = await axios.get(APP_URLs.aboutUs.getAboutUs, config);
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

//banner
const getAllBanners = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.banners.bannerComnonApi}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addBanner = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.banners.bannerComnonApi,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getAllSubscription = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.subscription.SubscriptionCommonAPI}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addSubscription = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.subscription.SubscriptionCommonAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const deleteSubscription = async (payloadData: any) => {
  try {
    const { data } = await axios.delete(
      `${APP_URLs.subscription.SubscriptionCommonAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addPaymentPrivacy = async (payloadData: any) => {
  try {
    const { data } = await axios.put(
      APP_URLs.paymentPrivacy.addPaymentPrivacy,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addPaymentTerms = async (payloadData: any) => {
  try {
    const { data } = await axios.put(
      APP_URLs.paymentTerms.addPaymentTerms,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addAboutUs = async (payloadData: any) => {
  try {
    const { data } = await axios.put(
      APP_URLs.aboutUs.addAboutUs,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addTerms = async (payloadData: any) => {
  try {
    const { data } = await axios.put(
      APP_URLs.termsAndCondition.addTerms,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addPrivacy = async (payloadData: any) => {
  try {
    const { data } = await axios.put(
      APP_URLs.privacyAndPolicy.addPrivacy,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

// faq
const getAllFAQs = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.faq.genericFAQAPI}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addEditGolfFAQ = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.faq.genericFAQAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getAppCustomerList = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.appUser.appCustomerList}?roleId=${query?.roleId}&offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getOffers = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.offers.offersCommon}?golfId=${query?.golfId}&offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addEditOffer = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.offers.offersCommon,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const deleteOffer = async (payloadData: any) => {
  try {
    const { data } = await axios.delete(
      `${APP_URLs.offers.offersCommon}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const deleteBanner = async (payloadData: any) => {
  try {
    const { data } = await axios.delete(
      `${APP_URLs.banners.bannerComnonApi}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const deleteFaq = async (payloadData: any) => {
  try {
    const { data } = await axios.delete(
      `${APP_URLs.faq.genericFAQAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getAllCompany = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.company.genericCompanyAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addEditCompany = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.company.genericCompanyAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const deleteCompany = async (payloadData: any) => {
  try {
    const { data } = await axios.delete(
      `${APP_URLs.company.genericCompanyAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addAdmin = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.appUser.addAdmin,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const editAdmin = async (payloadData: any) => {
  try {
    const { data } = await axios.put(
      APP_URLs.appUser.editAdmin,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};
const changePassword = async (payloadData: any) => {
  try {
    const { data } = await axios.put(
      APP_URLs.changeUserPassword.genericAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
    // snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditThirdPartyAPI = async (payloadData: IThirdPartyAPI) => {
  try {
    const { data } = await axios.post(
      APP_URLs.golfEndPoints.addEditThirdPartyAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const suspendActiveAdmin = async (payloadData: any) => {
  try {
    const { data } = await axios.put(
      APP_URLs.appUser.suspendAdmin,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const uploadPicture = async (payloadData: any) => {
  try {
    const { data } = await axios.put(APP_URLs.mediaUpload, payloadData, config);
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getSlotBooking = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.booking.genericSlotBookingAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

// const addEditBookingSlot = async (payloadData: IBookingSlot) => {
//   try {
//     const { data } = await axios.post(
//       APP_URLs.booking.genericSlotBookingAPI,
//       payloadData,
//       config,
//     );
//     return data;
//   } catch (error: any) {
//     snackActions.dismissibleError(error?.message ?? "Something went wrong");
//   }
// };

const getSubscriptionInfo = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.subscriptionInfo.genericSubscriptionInfoAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getAllCoupons = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.coupon.genericCouponAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addEditCoupon = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.coupon.genericCouponAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const deleteCoupon = async (payloadData: any) => {
  try {
    const { data } = await axios.delete(
      `${APP_URLs.coupon.genericCouponAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getContactUsList = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.contactus.getContactusList}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const adminContactUs = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      `${APP_URLs.contactus.adminContactUs}`,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getMembershipDescription = async () => {
  try {
    const { data } = await axios.get(
      APP_URLs.membershipDescription.genericMembershipDescription,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addEditMembershipDescription = async (payloadData: any) => {
  try {
    const { data } = await axios.put(
      APP_URLs.membershipDescription.genericMembershipDescription,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getSquezzMeList = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.squezzMe.getSquezzMeList}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&type=${query?.type ?? ""}&golfType=${
        query?.golfType ?? ""
      }&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const updateSquezzMeStatus = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.squezzMe.updateRequestStatus,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getInterestList = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.interest.genericInterestAPI}?type=${query?.type ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addEditInterest = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.interest.genericInterestAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getCategoryGolf = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.golf.genericCategoryGolf}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteInterest = async (payloadData: any) => {
  try {
    const { data } = await axios.delete(
      `${APP_URLs.interest.genericInterestAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const deleteApparel = async (payloadData: any) => {
  try {
    const { data } = await axios.delete(
      `${APP_URLs.apparel.genericApparelAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getApparelList = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.apparel.genericApparelAPI}?type=${query?.type ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const addEditApparel = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.apparel.genericApparelAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const getWaitingList = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.notify.generiWaitingListAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const notify = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      APP_URLs.notify.genericWaitingUserNotifyAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error.response.data.message ?? "Something went wrong");
  }
};

const getDashboard = async (query: any) => {
  try {
   const params: any = {};

   if(query?.startDate){
    params.startDate = query?.startDate;
   } 
    if(query?.endDate){
    params.endDate = query?.endDate;
   } 
    if(query?.startTime){
    params.startTime = query?.startTime;
   } 
   if(query?.endTime){
    params.endTime = query?.endTime;
   } 
    if(query?.golfId){
    params.golfId = query?.golfId;
   } 
    if(query?.player){
    params.player = query?.player;
   } 

   if(query?.responseTime){
    params.responseTime = query?.responseTime;
   } 
  

   const queryString = new URLSearchParams(params).toString();
    const { data } = await axios.get(
      `${APP_URLs.dashboard.getDashboard}?${queryString}`,
     
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(
      error.response.data.message ??
        "Something went wrong!, please try again",
    );
  }
};

const getAdminList = async (query: any) => {
  try {
    const { data } = await axios.get(
      `${APP_URLs.appUser.adminList}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}&golfId=${query?.golfId}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

const deleteGolfAdmin = async (payloadData: any) => {
  try {
    const { data } = await axios.post(
      `${APP_URLs.appUser.deleteGolfAdmin}`,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};


export {
  activeDeActiveGolfClub,
  addAboutUs,
  addAdmin,
  addBanner,
  addEditApparel,
  // addEditBookingSlot,
  addEditCompany,
  addEditCoupon,
  addEditGolfFAQ,
  addEditGolfHoles,
  addEditInterest,
  addEditMembershipDescription,
  addEditOffer,
  addEditThirdPartyAPI,
  addGolfClub,
  addPaymentPrivacy,
  addPaymentTerms,
  addPrivacy,
  addSubscription,
  addTerms,
  adminContactUs,
  changePassword,
  deleteApparel,
  deleteBanner,
  deleteCompany,
  deleteCoupon,
  deleteFaq, deleteGolfAdmin, deleteGolfClub,
  deleteInterest,
  deleteOffer,
  deleteSubscription,
  editAdmin,
  getAboutUs, getAdminList, getAllBanners,
  getAllCompany,
  getAllCoupons,
  getAllFAQs,
  getAllGolfClub,
  getAllSubscription,
  getAppCustomerList,
  getApparelList,
  getCategoryGolf,
  getCities,
  getContactUsList,
  getCountries, getDashboard, getGolfClub,
  getInterestList,
  getMembershipDescription,
  getOffers,
  getPaymentPrivacy,
  getPaymentTerms,
  getPrivacy,
  getSlotBooking,
  getSquezzMeList,
  getStates,
  getSubscriptionInfo,
  getTerms,
  getWaitingList,
  notify,
  suspendActiveAdmin,
  updateSquezzMeStatus,
  uploadPicture
};

